import { LangDefinition } from '@ngneat/transloco'

import { AppConfig } from '~core/config/config'

const availableLanguages: string[] = AppConfig.TRANSLOCO.availableLangs.map((lang: string | LangDefinition) => {
  return typeof lang === 'string' ? lang : lang['id']
})

export const translocoScopeLoader = (importer: any, root = 'i18n') => {
  return availableLanguages.reduce((acc: any, lang) => {
    acc[lang] = () => importer(lang, root)
    return acc
  }, {})
}

export const triggerDownlaod = (dataString, filename): void => {
  const element = document.createElement('a')
  element.setAttribute('href', dataString)
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}
